<template lang="pug">
	.mb-4.resources-table(v-if='project.hasResources()')
		.d-flex.justify-content-between.align-items-center.mb-4
			dropdown-general(:list="dropdownList" :size="'large'" @onChange='changeStatus')
			span.btn.btn-primary.plus-primary.resources-table__btn(@click="$emit('click', $event)")
				span.plus-primary
				span(v-if="!isMobile") {{ $t('resources.createEvent.btn') }}
		resources-card.mb-4(v-for="(card, i) in resources" :key="`resources-${i}`" :card="card")
		
		.d-flex.flex-column.pt-md-5.my-5(v-if="project.channels.length == 0 && this.selectedStatus == 2")
			h1.text-center.mb-2 😢
			span.b1.text-center {{ $t('project.notChannel') }}
			span.b3.text-center.text-grey {{ $t('project.addFirstChannel') }}

		.d-flex.flex-column.pt-md-5.my-5(v-if="project.groups.length == 0 && this.selectedStatus == 3")
			h1.text-center.mb-2 😢
			span.mb-2.b1.text-center {{ $t('project.notGroup') }}
			span.b3.text-center.text-grey {{ $t('project.addFirstGroup') }}
</template>

<script>
import DropdownGeneral from '@/components/Dropdown/DropdownGeneral';
import ResourcesCard from '@/components/Cards/ResourcesCard';
import {mapGetters} from 'vuex'

export default {
	name: 'ResourcesTable',
	components: {
		DropdownGeneral,
		ResourcesCard
	},
	data: () => ({
		dropdownList: [],
		selectedStatus: 1
	}),
	computed: {
		...mapGetters({
			project : 'project/opened',
		}),
		resources () {
			if ( !this.project ) return [];

			if ( this.selectedStatus == 1 ) return [...this.project.channels, ...this.project.groups];
			if ( this.selectedStatus == 2 ) return this.project.channels;
			if ( this.selectedStatus == 3 ) return this.project.groups;

			return [];
		}
	},
	created() {
		this.getDropdownList();
	},
	methods: {
		changeStatus ( i ) {
			this.selectedStatus = i;
		},
		getDropdownList() {
			this.dropdownList = [
				{
					id: 1,
					title: this.$t('resources.dropdown[0]')
				},
				{
					id: 2,
					title:  this.$t('resources.dropdown[1]')
				},
				{
					id: 3,
					title:  this.$t('resources.dropdown[2]')
				}
			];
		}
	},
}
</script>
<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.resources-table {
	&__btn {
		min-width: 256px;

		@include respond-below(sm) {
			min-width: 50px;
			box-shadow: none !important;
		}
	}
}
</style>
